import React from 'react';

function RoundedTitle(props) {
  return (
    <div className="title-container">
      <div className="rounded-title">
        { props.children }
      </div>
    </div>
  )
}

export default RoundedTitle;