import React from 'react';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";

import userActions from '../../actions/user.actions'

import RoundedTitle from '../atoms/RoundedTitle';
import StatisticsDisplay from '../molecules/StatisticsDisplay';
import NavigateMenuBar from '../molecules/NavigateMenuBar';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

class PracticePostMatch extends React.Component {

  render() {
    return (
      <div className="post-game-container">
        <RoundedTitle>Post Match Stats</RoundedTitle>
        { this.props.game ? (
          <StatisticsDisplay
            gameId={this.props.game.gameId}
          >
          </StatisticsDisplay>
        ):(
          this.props.error ? (
            <div>{this.props.error}</div>
          ):(
            <ClipLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={this.props.loading}
          />)
        ) }
        <NavigateMenuBar></NavigateMenuBar>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    archivedGame: state.getArchivedGame.archivedGame,
    error: state.getArchivedGame.error,
    game: state.createPracticeGame.game,
    loading: state.loading,
  }
}

const actionCreators = {
  getArchivedGame: userActions.getArchivedGame,
}

export default connect(mapStateToProps, actionCreators)(PracticePostMatch);
