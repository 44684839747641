export default function updateGameState(state = {accurateTo: 0, syncedTo: 0, gameOver: false}, action) {
  switch (action.type) {
    case "UPDATE_GAME_STATE": {
      return {
        accurateTo: action.update.result.accurateTo,
        syncedTo: action.update.result.syncedTo,
      }
    }
    case "END_GAME_STATE": {
      return {
        gameOver: true,
      }
    }
    case "CLEAN_GAME_STATE": {
      return {
        accurateTo: 0,
        syncedTo: 0,
        gameOver: false,
      }
    }
    default:
      return state
  }
}