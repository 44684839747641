import React from 'react';
import { Link } from 'react-router-dom';

class NavigateMenuBar extends React.Component {
  render() {
    return (
      <div className="NavigateMenuBar">
        <Link to="/play" className="navigate-button navigate-restart">
          <span className="material-icons">
            replay
          </span>
        </Link>
        <Link to="/welcome" className="navigate-button navigate-home">
          <span className="material-icons">
            home
          </span>
        </Link>
      </div>
    );
  }
}

export default NavigateMenuBar