import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import socketMiddleware from '../middlewares/socket.middleware'
import rootReducer from '../reducers/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(socketClient) {
  return createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk),
      applyMiddleware(socketMiddleware(socketClient)),
    )
  );
};