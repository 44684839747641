import React from 'react';
import {
  Link
} from 'react-router-dom';
import logo from '../../images/logo.png'

function LandingHeader() {
  return (
    <div className="header-container">
      <Link to="/welcome"><img src={logo} height="80px" className="logo"></img></Link>
      <div className="spacer"></div>
      <div className="promo-dark">
        A project by <a href="http://linkedin.com/in/gray-lewis">Gray Lewis</a>.
      </div>
    </div>
  );
}

export default LandingHeader;