import io from 'socket.io-client';
import gameActions from '../actions/game.actions'
import store from '../storeProvider'

import { API_SOCKET_URL } from '../constants/api.constants';

const API_SOCKET_URL_ = (process.env.REACT_APP_PROD == "true") ? API_SOCKET_URL.PROD:API_SOCKET_URL.DEV;

// Example conf. You can move this to your config file.
const host = API_SOCKET_URL_;


export default class socketAPI {
  socket;

  connect() {
    const regex = /(")+/g

    const connectionOptions =  {
      forceNew: true,
      reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
      timeout: 10000,     
      auth: { token: `Bearer ${localStorage.getItem('token').replace(regex, '')}` }     //before connect_error and connect_timeout are emitted.
    };
  
    this.socket = io(host, connectionOptions);
    
    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => {
        if (localStorage.getItem('token')) {
          const regex = /(")+/g
          const jwt = localStorage.getItem('token').replace(regex, '');
     //     this.socket
      //      .emit('authenticate', { token: jwt })
      //      .on('authenticated', () => {console.log('JWT Authenticated.')})
        }

        resolve();
      });
      this.socket.on('connect_error', (error) => reject(error));
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.on('disconnect', () => {this.socket = null})

      this.socket.close();
      resolve();
    });
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.');

      return this.socket.emit(event, data, (response) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response.error) {
          console.error(response.error);
          return reject(response.error);
        }

        if (response) {
          return resolve(response);
        }
        
        return resolve();
      });
    });
  }

  listenForGameEnd() {
    return new Promise((resolve, reject) => {
      this.socket.on('game-over', () => {
        store.dispatch({type: "END_GAME_STATE"}); 
      });

      resolve();
    })
  }

  on(event, fun) {
    console.log('RUNNING')
    // No promise is needed here, but we're expecting one in the middleware.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.');

      this.socket.on(event, fun);
      resolve();
    });
  }
}
