export default function endGame(state = {gameOver: false}, action) {
  switch (action.type) {
    case "END_GAME":
      return { 
        gameOver: true,
      }
    case "RESET_GAME":
      return {
        gameOver: true
      }
    default:
      return state
  }
}