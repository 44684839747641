export default function authUser(state = {}, action) {
  switch (action.type) {
    case "AUTH_REQUEST":
      return {};
    case "AUTH_SUCCESS":
      return { user: action.user };
    case "AUTH_FAILURE":
      return { error: action.error };
    default:
      return state
  }
}