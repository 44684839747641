export default function updateTimer(state = {time: 60}, action) {
  switch (action.type) {
    case "UPDATE_TIMER":
      const timeState = {
        time: action.time
      }
      return timeState
    default:
      return state
  }
}