export default function getLeaderboard(state = {}, action) {
    switch (action.type) {
      case "GET_LEADERBOARD_REQUEST":
        return {};
      case "GET_LEADERBOARD_SUCCESS":
        return { leaderboard: action.leaderboard };
      case "GET_LEADERBOARD_FAILURE":
        return { error: 'Leaderboard not found.' };
      default:
        return state
    }
  }