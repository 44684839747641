import { API_URL } from '../constants/api.constants';
const API_URL_ = (process.env.REACT_APP_PROD == "true") ? API_URL.PROD:API_URL.DEV;


function registerUser(user) {
  const requestOptions = {
      method: 'POST',
      timeout: 5000,      
      headers: { 
        'Content-Type': 'application/json',

      },
      body: JSON.stringify(user),
  };
  console.log(requestOptions)
  return fetch(`${API_URL_}/register`, requestOptions).then(handleResponse);
}

function getWordlist(wordlist) {
  const requestOptions = {
    method: 'GET',
    timeout: 5000,
    headers: { 
      'Content-Type': 'application/json', 
    },
  };

  return fetch(`${API_URL_}/wordlist/${wordlist}`, requestOptions).then(handleResponse);
}

function getArchivedGame(gameId) {
  console.log(gameId)

  const requestOptions = {
    method: 'GET',
    timeout: 5000,
    headers: { 
      'Content-Type': 'application/json', 
    },
  };

  return fetch(`${API_URL_}/match/${gameId}`, requestOptions).then(handleResponse);
}

function getLeaderboard(leaderboardId) {
  console.log(leaderboardId)

  const requestOptions = {
    method: 'GET',
    timeout: 5000,
    headers: { 
      'Content-Type': 'application/json', 
    },
  };

  return fetch(`${API_URL_}/leaderboard/${leaderboardId}`, requestOptions).then(handleResponse);
}

function loginUser(email, password) {
  const requestOptions = {
    method: 'POST',
    timeout: 5000,
    headers: { 
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify({
      email,
      password
    }),
  };

  return fetch(`${API_URL_}/login`, requestOptions).then(handleResponse);
}

function authUser(jwt) {
  const regex = /(")+/g
  jwt = jwt.replace(regex, '')
  const requestOptions = {
    method: 'GET',
    timeout: 5000,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': `Bearer ${jwt}`
    },
  };
  return fetch(`${API_URL_}/me`, requestOptions).then(handleResponse);
}

function createPracticeGame(jwt) {
  const regex = /(")+/g
  jwt = jwt.replace(regex, '')
  const requestOptions = {
    method: 'GET',
    timeout: 5000,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': `Bearer ${jwt}`
    },
  };
  return fetch(`${API_URL_}/createPracticeGame`, requestOptions).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          console.log(response);

          if (response.status === 401) {
              // auto logout if 401 response returned from api
              logout();
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }
      return data;
  });
}

const userService = {
  registerUser,
  loginUser,
  logout,
  authUser,
  createPracticeGame,
  getWordlist,
  getArchivedGame,
  getLeaderboard,
}

export default userService;