export default function getWordlist(state = {}, action) {
  switch (action.type) {
    case "WORDLIST_REQUEST":
      return {};
    case "WORDLIST_SUCCESS":
      return { wordlist: action.wordlist };
    case "WORDLIST_FAILURE":
      return {};
    default:
      return state
  }
}