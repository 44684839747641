import userService from '../services/user.service';
import { SubmissionError } from 'redux-form';

function registerUser(user) {
  return (dispatch) => {
      dispatch(request(user));
      return userService.registerUser(user)
          .then(
              message => { 
                  dispatch(success(message));
                  return message;
              },
              error => {
                  dispatch(failure(error.toString()));
                  return new SubmissionError({ _error: error });
              }
          );
  };

  function request(user) { return { type: 'REGISTER_REQUEST', user } }
  function success(user) { return { type: 'REGISTER_SUCCESS', user } }
  function failure(error) { return { type: 'REGISTER_FAILURE', error } }
}

function loginUser(email, password) {
  return (dispatch) => {
    dispatch(request(email, password));

    return userService.loginUser(email, password)
      .then(
        token => {
          localStorage.setItem('token', JSON.stringify(token));
          dispatch(success());

          return token;
        },
        error => {
          dispatch(failure(error.toString()));
          return new SubmissionError({ _error: error });
        }
      );
  }

  function request(user) { return { type: 'LOGIN_REQUEST', user } }
  function success(user) { return { type: 'LOGIN_SUCCESS', user } }
  function failure(error) { return { type: 'LOGIN_FAILURE', error } }
}

function authUser(jwt) {
  return (dispatch) => {
    dispatch(request(jwt));

    return userService.authUser(jwt)
      .then(
        user => {
          dispatch(success(user));
          return user;
        },
        error => {
          console.log(error)
          dispatch(failure(error.toString()));
          return new SubmissionError({ _error: error });          
        }
      )
  }

  function request(jwt) { return { type: 'AUTH_REQUEST', jwt } }
  function success(user) { return { type: 'AUTH_SUCCESS', user } }
  function failure(error) { return { type: 'AUTH_FAILURE', error } }
}

function createPracticeGame(jwt) {
  return (dispatch) => {
    dispatch(request(jwt));
    return userService.createPracticeGame(jwt)
      .then(
        gameId => {
          console.log(gameId)
          dispatch(success(gameId));
          return gameId;
        },
        error => {
          console.log(error)
          dispatch(failure(error.toString()));
          return new SubmissionError({ _error: error });          
        }
      )
  }

  function request(jwt) { return { type: 'CREATE_PRACTICE_GAME_REQUEST', jwt } }
  function success(gameId) { return { type: 'CREATE_PRACTICE_GAME_SUCCESS', gameId } }
  function failure(error) { return { type: 'CREATE_PRACTICE_GAME_FAILURE', error } }
}

function getWordlist(wordlist) {
  return (dispatch) => {
    dispatch(request());

    return userService.getWordlist(wordlist)
      .then(
        wordlist => {
          dispatch(success(wordlist));
          return wordlist;
        },
        error => {
          console.log(error)
          dispatch(failure(error.toString()));
          return new SubmissionError({ _error: error });          
        }
      )
  }

  function request() { return { type: 'WORDLIST_REQUEST' } }
  function success(wordlist) { return { type: 'WORDLIST_SUCCESS', wordlist } }
  function failure(error) { return { type: 'WORDLIST_FAILURE', error } }
}

function getArchivedGame(gameId) {
  return (dispatch) => {
    dispatch(request());

    return userService.getArchivedGame(gameId)
      .then(
        archivedGame => {
          dispatch(success(archivedGame));
        },
        error => {
          console.log(error)
          dispatch(failure(error.toString()));
          return new SubmissionError({ _error: error });          
        }
      )
  }

  function request() { return { type: 'ARCHIVED_GAME_REQUEST' } }
  function success(archivedGame) { return { type: 'ARCHIVED_GAME_SUCCESS', archivedGame } }
  function failure(error) { return { type: 'ARCHIVED_GAME_FAILURE', error } }
}

function getLeaderboard(leaderboardId) {
  return (dispatch) => {
    dispatch(request());

    return userService.getLeaderboard(leaderboardId)
      .then(
        leaderboard => {
          dispatch(success(leaderboard));
        },
        error => {
          console.log(error)
          dispatch(failure(error.toString()));
          return new SubmissionError({ _error: error });          
        }
      )
  }

  function request() { return { type: 'GET_LEADERBOARD_REQUEST' } }
  function success(leaderboard) { return { type: 'GET_LEADERBOARD_SUCCESS', leaderboard } }
  function failure(error) { return { type: 'GET_LEADERBOARD_FAILURE', error } }
}

const actionCreators = {
  registerUser,
  loginUser,
  authUser,
  createPracticeGame,
  getWordlist,
  getArchivedGame,
  getLeaderboard,
}

export default actionCreators;