import React from 'react';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";

import userActions from '../../actions/user.actions';
import gameActions from '../../actions/game.actions';

class StatisticsDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.gameId) {
      this.props.getArchivedGame(this.props.gameId)
    }
  }

  render() {
    return (
      <div className="statistics-display">
        <div className="statistics-display-title">
          <div className="game-title">
            { this.props.archivedGame && 
              <span>{`${ this.props.archivedGame.type } Game`}</span>
            }
          </div>
          <div className="divider"></div>
          <div className="date-display">
            { this.props.archivedGame &&
              <span>{ new Date(this.props.archivedGame.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) }</span>
            }</div>
        </div>
        <div className="statistics-container">
          { this.props.archivedGame ? (
          <div className="statistics-section">
            <div className="statistic-row">
              <div className="statistic-title">Your WPM</div>
              <div className="statistic-display">
                  <span>{ Math.floor(this.props.archivedGame.stats.averageWPM) } </span>
              </div>
            </div>
            <div className="statistic-row">
              <div className="statistic-title">Characters Typed</div>
              <div className="statistic-display">
                  <span>{ this.props.archivedGame.stats.charactersTyped }</span>
              </div>
            </div>
            <div className="statistic-row">
              <div className="statistic-title">Mistake Count</div>
              <div className="statistic-display mistake-display">
                  <span>{ this.props.archivedGame.stats.mistakeCount }</span>
              </div>
            </div>
          </div>
          )
          :
          (
            <div className="statistics-section">
            <div className="statistic-row">
              <div className="statistic-title">Your WPM</div>
              <div className="statistic-display">
                  <span><ClipLoader color="#235363"></ClipLoader></span>
              </div>
            </div>
            <div className="statistic-row">
              <div className="statistic-title">Characters Typed</div>
              <div className="statistic-display">
                  <span><ClipLoader color="#235363"></ClipLoader></span>
              </div>
            </div>
            <div className="statistic-row">
              <div className="statistic-title">Mistake Count</div>
              <div className="statistic-display mistake-display">
                  <span><ClipLoader color="#235363"></ClipLoader></span>
              </div>
            </div>
          </div>
          )} 
          <div className="statistics-tips">
            <div className="statistics-tip">Your average typing speed. (Characters per minute / 5)</div>
            <div className="statistics-tip">The total amount of characters you were able to type.</div>
            <div className="statistics-tip">The number of times you had to use backspace</div>
          </div>
          
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    archivedGame: state.getArchivedGame.archivedGame,
    error: state.getArchivedGame.error,
    game: state.createPracticeGame.game,
    loading: state.loading,
  }
}

const actionCreators = {
  getArchivedGame: userActions.getArchivedGame,
  generateStatistics: gameActions.generateStatistics,
}

export default connect(mapStateToProps, actionCreators)(StatisticsDisplay);