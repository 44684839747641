export default function connectToGame(state = {}, action) {
  switch(action.type) {
    case 'CONNECT_TO_GAME':
      return {
        ...state,
        isSending: true,
      };
    case 'CONNECT_TO_GAME_SUCCESS':
      return {
        isConnected: true,
        game: action.result, 
      }
    default: {
      return state;
    }
  }
}