export default function createPracticeGame(state = {}, action) {
  switch (action.type) {
    case "CREATE_PRACTICE_GAME_REQUEST":
      return { game: {} };
    case "CREATE_PRACTICE_GAME_SUCCESS":
      return { game: action.gameId };
    case "CREATE_PRACTICE_GAME_FAILURE":
      return {};
    default:
      return state
  }
}