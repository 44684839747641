import React from 'react';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useLocation,
  withRouter
} from 'react-router-dom';

//COOKIES
import { withCookies } from 'react-cookie';

//STRUCTURE
import Header from './components/organisms/Header';

//LANDING
import LandingHeader from './components/organisms/LandingHeader';
import RegisterForm from './components/molecules/RegisterForm';
import RegisterGraphic from './components/molecules/RegisterGraphic';

import LandingSectionOne from './components/molecules/LandingSectionOne';


//LOGIN
import LoginForm from './components/organisms/LoginForm';

//WELCOME PAGE
import Welcome from './components/organisms/Welcome';

//SPEEDTYPER
import TypingGamePractice from './components/molecules/TypingGamePractice';
import PracticePostMatch from './components/organisms/PracticePostMatch';
import StatisticsDisplay from './components/molecules/StatisticsDisplay';

//ATOMS
import RoundedTitle from './components/atoms/RoundedTitle'

//PAGE TRANSITION ANIMATIONS
import { motion, AnimatePresence } from "framer-motion"

import './stylesheets/destyle.css'
import './stylesheets/main.scss';

function StatisticsWrapper() {
  let { matchId } = useParams();
  return (
  <StatisticsDisplay gameId={matchId}>
    <RoundedTitle>Archived Match</RoundedTitle>
  </StatisticsDisplay>
  );
}

const pageTransition = { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] };

function TransitionWrapper(props) {
 return(
  <motion.div 
    style={{height: '100%'}}
    initial={{ position: 'relative', left: '100px', opacity: .0 }}
    animate={{ position: 'relative', left: '0px', opacity: 1 }}
    exit={{ position: 'relative', left: '-100px', opacity: .0, }}
    transition={pageTransition}
    className="transition-container"
  > 
    {props.children}
 </motion.div>  
 )
}


function App() {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <LandingHeader></LandingHeader>
            <div className="background-container">
              <TransitionWrapper>
                <LandingSectionOne ></LandingSectionOne>
              </TransitionWrapper>
            </div>

          </Route>
          <Route path="/login">
            <LandingHeader></LandingHeader>
              <div className="background-container">
                <TransitionWrapper>
                  <LoginForm></LoginForm>
                </TransitionWrapper>
              </div>
          </Route>
          <Route path="/register">
            <LandingHeader></LandingHeader>
            <div className="background-container">
              <TransitionWrapper>
              <div className="registration-form-container">
                <RegisterForm></RegisterForm>
                <RegisterGraphic></RegisterGraphic>
              </div>
              </TransitionWrapper>
            </div>
          </Route>
          <PrivateRoute path="/welcome">
            <LandingHeader></LandingHeader>
            <TransitionWrapper>
              <Welcome></Welcome>
            </TransitionWrapper>
          </PrivateRoute>
          <PrivateRoute path="/play">
            <Header></Header>
            <TransitionWrapper>
              <TypingGamePractice
              ></TypingGamePractice>
            </TransitionWrapper>
          </PrivateRoute>
          <PrivateRoute path="/postmatch">
            <TransitionWrapper>
              <PracticePostMatch></PracticePostMatch>
            </TransitionWrapper>
          </PrivateRoute>
          <Route path="/match/:matchId">
            <StatisticsWrapper>
            </StatisticsWrapper>
          </Route>
      </Switch>
    </AnimatePresence>
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('token') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default withCookies(connect()(App));
