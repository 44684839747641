import React from 'react';

class ChallengeRow extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.activeLine !== this.props.activeLine) {
      return true
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="challenge-row">{ this.props.content }</div> 
    )
  }

}

export default ChallengeRow;