export default function getArchivedGame(state = {}, action) {
  switch (action.type) {
    case "ARCHIVED_GAME_REQUEST":
      return {};
    case "ARCHIVED_GAME_SUCCESS":
      return { archivedGame: action.archivedGame };
    case "ARCHIVED_GAME_FAILURE":
      return { error: 'Game not found.' };
    default:
      return state
  }
}