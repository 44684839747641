export default function loginUser(state = {}, action) {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {};
    case "LOGIN_SUCCESS":
      return { loggedIn: true };
    case "LOGIN_FAILURE":
      return {};
    default:
      return state
  }
}