function updateTimer(time) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_TIMER', time })
  }
}

function endGame() {
  return (dispatch) => {
    dispatch({ type: "END_GAME_STATE" })
  }
}

function connectToSocket() {
  return {
    type: 'socket',
    types: ['CONNECT', 'CONNECT_SUCCESS', 'CONNECT_FAIL'],
    promise: (socket) => socket.connect(),
  }
}

function updateGameState(update) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_GAME_STATE', update})
  }
}

function cleanGameState() {
  return (dispatch) => {
    dispatch({ type: 'CLEAN_GAME_STATE'})
  }
}


function disconnectFromSocket() {
  return {
    type: 'socket',
    types: ['DISCONNECT', 'DISCONNECT_SUCCESS', 'DISCONNECT_FAIL'],
    promise: (socket) => socket.disconnect(),
  }
}

function connectToGame(gameId) {
  return {
    type: 'socket',
    types: ['CONNECT_TO_GAME', 'CONNECT_TO_GAME_SUCCESS', 'CONNECT_TO_GAME_FAIL'],
    promise: (socket) => socket.emit('game-id', gameId),
  }
}
function listenForGameEnd() {
  return {
    type: 'socket',
    types: ['LISTEN_FOR_GAME_END', 'LISTEN_FOR_GAME_END_SUCCESS', 'LISTEN_FOR_GAME_END_FAIL'],
    promise: (socket) => socket.listenForGameEnd(),
  }
}

function sendGameUpdate(input) {
  return {
    type: 'socket',
    types: ['SEND_GAME_UPDATE', 'SEND_GAME_UPDATE_SUCCESS', 'SEND_GAME_UPDATE_FAIL'],
    promise: (socket) => socket.emit('update', input),
  }
}

function generateStatistics(events) {
  return (dispatch) => {
    let averageWPM = 0;
    let charactersTyped;
    let mistakeCount;
    if (events) {
      const onlyAdditions = events.filter((val) => val.addition);
      const onlyMistakes = events.filter((val) => !val.addition);

      averageWPM = onlyAdditions.length / 5;
      charactersTyped = onlyAdditions.length;
      mistakeCount = onlyMistakes.length;
    }

    const stats = {
      averageWPM,
      charactersTyped,
      mistakeCount
    }

    dispatch({ type: 'GENERATE_STATISTICS', stats })
  }
}

const actionCreators = {
  generateStatistics,
  connectToSocket,
  connectToGame,
  disconnectFromSocket,
  sendGameUpdate,
  updateGameState,
  updateTimer,
  endGame,
  cleanGameState,
  listenForGameEnd,
}

export default actionCreators;