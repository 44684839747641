import React from 'react';
import { connect } from "react-redux";
import timer from '../../images/timer-black-36dp.svg'

class MatchHistory extends React.Component {

  renderCards(matchHistory) {
    const cards = matchHistory.map((val, index) => {
      const date = new Date((matchHistory[index].date))
      return (
      <a key={index} href={`/match/${val.gameId}`}>
        <div key={index} className="history-card">
          <div className="history-card-left">
            <div className="history-card-title">{`${val.type} Game`}</div>
              <span className="date-tag tag">{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</span>
              <span className="time-tag tag">{date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
          </div>
          <div className="history-card-wpm">
            <div className="wpm-display">{Math.floor(val.stats.averageWPM)}<span className="wpm-label"> WPM</span></div>
          </div>
          <div className="history-card-match-length">
            <div className="match-length-display">
              <img src={timer}></img>
              <span className="match-length-label">60s</span>
            </div>
          </div>
        </div>
      </a>
      )
    })

    return cards
  }

  render() {
    const { user } = this.props

    return (
      <div>
        { user && 
          <div>{this.renderCards(user.matchHistory)}</div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const mapStateToProps = {
    user: state.authUser.user
  }

  return mapStateToProps;
}

export default connect(mapStateToProps, null)(MatchHistory)