import React from 'react';
import { connect } from "react-redux";
import userActions from '../../actions/user.actions'

import { Link } from 'react-router-dom';

import MatchHistory from '../molecules/MatchHistory';
import Leaderboard from '../molecules/Leaderboard';

class Welcome extends React.Component {
  verify = (jwt) => {
    return this.props.authUser(jwt).then((res) => {
      if (res.errors) {
        return Promise.reject(res);
      } else if (res.message) {
        return Promise.resolve(res.message);
      }
    })
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      const jwt = localStorage.getItem('token');
      this.verify(jwt) 
        .catch((error) => {
          throw error;
        })
    } 
  }

  render() {
    const { user } = this.props

    return (
      <div>
        { user &&
          <div className="welcome-container">
            <div className="welcome-cards">
              <div className="welcome-cards-left">
                <div className="welcome-profile-card welcome-card">
                  <div className="welcome-title">
                    Welcome, { `${user.firstName} ${user.lastName}` }
                  </div>
                  <div className="profile-tags">
                    <span className="tag">Joined on { new Date(user.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) }</span>
                    <span className="tag">Verified Account</span>
                  </div>
                  <div className="profile-disclaimer">
                    Thank you for checking out an early version of SpeedTyper!<br></br><br></br>
                    Many features (almost all of them) are still missing, such as multiplayer racing and publicly facing profiles. These features are coming, but in the mean time, try out the beta version of the practice mode. <br></br><br></br>
                    - Gray Lewis
                  </div>
                </div>
                <Link to="/play">
                <div className="create-game">
                  START PRACTICE MODE
                </div>
                </Link>
              </div> 
              <div className="welcome-cards-right">
                <div className="welcome-history-card welcome-card">
                  <div className="welcome-title">
                    Match History
                  </div>
                  <MatchHistory></MatchHistory>
                </div>
                <div className="welcome-leaderboard-card welcome-card">
                  <div className="welcome-title">
                    Leaderboard
                  </div>
                  <Leaderboard></Leaderboard>
                </div>
              </div>
            </div>
        </div>
      }
      </div>
    );
  }
}

function mapStateToProps(state) {
  let user
  if (state.authUser) {
    user = state.authUser.user;
  } else {
    user = {
      firstName: "dummy",
      lastName: "dummy"
    }
  }

  return {
    user
  }
}

const actionCreators = {
  authUser: userActions.authUser,
}

export default connect(mapStateToProps, actionCreators)(Welcome);