import React from 'react';

import { Link } from 'react-router-dom';

import LandingImageOne from '../../images/web_developer.svg'
import Expand from '../../images/expand_more-white-48dp.svg';


function LandingSectionOne() {
    return (
        <div style={{height: '100%'}} className='LandingSectionOne'>
            <div className="landing-container landing-container-1">
                <div className="landing-left">
                    <div className="landing-text-container">
                        <div className="landing-title">SpeedTyper is the <nobr>ad-free</nobr>, social, <nobr>type-racing</nobr> game.</div>
                        <div className="landing-text">We spend a LOT of time typing in the digital age. The faster we type, the more time we save. Have some fun, and save some time with SpeedTyper - the ad-free, social, type-racing platform.</div>

                        <ul>
                            <li>Play with your friends</li>
                            <li>Watch yourself improve</li>
                            <li>Utilize extensive performance statistics</li>
                            <li>Share replays of your matches, and race against your past self.</li>
                        </ul>
                    </div>
                    <div className="landing-buttons">
                        <Link to='/register'><div className="landing-button register-button button">Make an Account</div></Link>
                        <Link to='/login'><div className="landing-button login-button button">Log In</div></Link>
                    </div>
                </div>
                <div className="landing-right">
                    <div className="landing-illustration-1">
                        <img src={LandingImageOne}></img>
                    </div>
                    <div className="scroll-prompt"><img src={Expand}></img>Scroll down to learn more</div>
                </div>
            </div>
        </div>
    );
}

export default LandingSectionOne;
