import React from 'react';
import {
  Link,
  Redirect
} from 'react-router-dom';

import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import userActions from '../../actions/user.actions'

class LoginForm extends React.Component {
  doSubmit = (response) => {
    const { email, password } = response;

    return this.props.loginUser(email, password).then((res) => {
      if (res.errors) {
        return Promise.reject(res);
      } else if (res.message) {
        return Promise.resolve(res.message);
      }
    })
  }

  render() {
    const { error, handleSubmit} = this.props;
    if (this.props.loggedIn === true) {
      return (<Redirect to="/welcome"/>)
    }
    return(
      <div className="login-form-container">
        <form className="general-form login-form" onSubmit={ handleSubmit(this.doSubmit) }>
          <div className="login-title">Log in to SpeedTyper</div>
  
          <Field className="general-input login-input" component="input" type="email" placeholder="Email" name="email"></Field>
          <Field className="general-input login-input password-input" component="input" type='password' placeholder="Password" name="password"></Field>
          { error && 
            <strong>{
              <div className="error-container">{error}</div>
            }</strong> 
          }
          <button component="input" className="submit-button" id="submit-button" type="submit">Submit</button>
          <div className="register-link">
            Don't have an account yet?&nbsp;
            <Link to="/register">Register</Link>
          </div>
        </form>
      </div>
    );
  }
}

LoginForm = reduxForm({
  form: 'login',
})(LoginForm)

const actionCreators = {
  loginUser: userActions.loginUser,
}

function mapStateToProps(state) {
  return {
    loggedIn: state.loginUser.loggedIn,

  }
}

export default connect(mapStateToProps, actionCreators)(LoginForm);